@import './colors.css';

@import './common_css/mandatoryFieldLabel.css';
@import './common_css/normalFieldLabel.css';
@import './common_css/loadingOverlay.css';
@import './common_css/cropper.css';
@import './common_css/uploadImage.css';
@import './common_css/HTMLTextEditor.css';
@import './common_css/reactTable.css';

.margin_top_row {
    margin-top: 1rem;
}

.margin_bottom_row {
    margin-bottom: 1rem;
}

.half_margin_top_row {
    margin-top: 0.5rem;
}


.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color);
}


.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.edit_header_icon_add {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.error_div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}


/* specifix */
.tab_content {
    height: 100vh !important;
    overflow-y: auto;
}

.configCard {
    min-height: 250px;
    padding-bottom:30px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tab_content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab_content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}